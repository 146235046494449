import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../pages/components/Layout"
import SEO from "../pages/components/Seo"

const BlogPostTemplate = ({ data }) => {
  const post = data.contentfulPost
  console.log("dfdfdfdfd", data)
  const siteTitle = "cp - portfolio"

  const coverImage = getImage(post.cover)

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.title}
        // description={post.frontmatter.description || post.excerpt}
      />
      <article className={`post-content ${coverImage || `no-image`}`}>
        <header className="post-content-header">
          <h1 className="post-content-title">{post.title}</h1>
        </header>

        {/* {post.frontmatter.description && (
            <p class="post-content-excerpt">{post.frontmatter.description}</p>
          )} */}
        <div style={{ width: "100%", marginBottom: "16px" }}>
          <GatsbyImage image={coverImage} alt={post.title} />
        </div>

        <div style={{ width: "100%" }}>
          {post.pictures.map(img => {
            const image = getImage(img)
            return <GatsbyImage style={{ marginBottom: "8px" }} image={image} />
          })}
        </div>
        <div
          className="post-content-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <footer className="post-content-footer">
          {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
        </footer>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      cover {
        gatsbyImageData(placeholder: TRACED_SVG, quality: 80, width: 1080)
      }
      slug
      title
      pictures {
        gatsbyImageData(placeholder: TRACED_SVG, quality: 80, width: 1080)
      }
    }
  }
`

BlogPostTemplate.propTypes = {
  data: PropTypes.node,
}

export default BlogPostTemplate
